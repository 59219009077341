const apiUrl = 'https://app-stage.opesjet.com/api';
export const environment = {
  production: false,
  apiHealthCheckUrl: `${apiUrl}/build-info`,
  baseUrl: `${apiUrl}/operator/v1`,
  firebase: {
    apiKey: 'AIzaSyCruM1oeQm5KQFOdAyOA4-TdaNAmI_P45A',
    authDomain: 'opesp-stage.firebaseapp.com',
    databaseURL: 'https://opesp-stage-default-rtdb.firebaseio.com',
    projectId: 'opesp-stage',
    storageBucket: 'opesp-stage.appspot.com',
    messagingSenderId: '433427321872',
    appId: '1:433427321872:web:f2d53565de07549813e695',
    measurementId: 'G-E2D57P04XF'
  },
  intercomAppId: 'crvnddyi',
  loggerUrl: 'https://fl-app-stage.opesjet.com:14241',
  dsn: 'https://5c0dbfef398a4e03855b96c0e37af84b@o4505006114537472.ingest.sentry.io/4505041085726720'
};
